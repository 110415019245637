
import { defineComponent } from "vue";
import { Section, TeamCard } from "@ritttzy/vue-landing";
import team from "@/assets/json/equipo.json";

export default defineComponent({
  name: "Equipo-section",
  components: { Section, TeamCard },
  data() {
    return {
      equipo: team,
    };
  },
});
