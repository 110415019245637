
import { defineComponent } from "vue";
import { Section } from "@ritttzy/vue-landing";
import listaBeneficios from "@/assets/json/beneficios.json";
export default defineComponent({
  name: "Beneficios-section",
  components: { Section },
  data() {
    return {
      beneficios: listaBeneficios,
    };
  },
  methods: {
    randomClass() {
      let num = Math.floor(Math.random() * 4) + 1;
      return "beneficio-c-" + num;
    },
  },
  created() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.beneficios.forEach((elem: any) => {
      elem.class = this.randomClass();
    });
  },
});
