
import { Section } from "@ritttzy/vue-landing";
import { Question } from "@ritttzy/vue-landing";
import questions from "@/assets/json/questions.json";
export default {
  name: "Preguntas-section",
  components: {
    Section,
    Question,
  },
  data() {
    return {
      preguntas: questions,
    };
  },
};
