import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menu = _resolveComponent("Menu")!
  const _component_Inicio = _resolveComponent("Inicio")!
  const _component_Equipo = _resolveComponent("Equipo")!
  const _component_Beneficios = _resolveComponent("Beneficios")!
  const _component_Productos = _resolveComponent("Productos")!
  const _component_Preguntas = _resolveComponent("Preguntas")!
  const _component_Contactos = _resolveComponent("Contactos")!
  const _component_RitttzyFooter = _resolveComponent("RitttzyFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Menu),
    _createVNode(_component_Inicio),
    _createVNode(_component_Equipo),
    _createVNode(_component_Beneficios),
    _createVNode(_component_Productos),
    _createVNode(_component_Preguntas),
    _createVNode(_component_Contactos),
    _createVNode(_component_RitttzyFooter)
  ], 64))
}