
import { Section } from "@ritttzy/vue-landing";
import { ContactUsForm, ContactCard } from "@ritttzy/vue-landing";

export default {
  name: "Escribenos-section",
  components: {
    Section,
    ContactCard,
    ContactUsForm,
  },
};
