
import { defineComponent } from "vue";
import { Section } from "@ritttzy/vue-landing";
import listaProductos from "@/assets/json/productos.json";
export default defineComponent({
  name: "Productos-section",
  components: { Section },
  data() {
    return {
      productos: listaProductos,
    };
  },
});
