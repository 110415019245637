
import { defineComponent } from "vue";
import { TopMenu } from "@ritttzy/vue-landing";
import logo from "@/assets/img/menu/Ganosalud.png";

export default defineComponent({
  name: "Menu-section",
  components: {
    TopMenu,
  },
  data() {
    return {
      active: false,
      activeId: "",
      ids: [
        "inicio",
        "equipo",
        "beneficios",
        "productos",
        "preguntas",
        "contactos",
      ],
      scrollOffset: 150,
      scrolled: false,
      img: { logo: logo },
    };
  },
});
