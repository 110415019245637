
import { defineComponent } from "vue";
import Menu from "@/components/Menu.vue";
import Inicio from "@/components/Inicio.vue";
import Equipo from "@/components/Equipo.vue";
import Beneficios from "@/components/Beneficios.vue";
import Productos from "@/components/Productos.vue";
import Preguntas from "@/components/Preguntas.vue";
import Contactos from "@/components/Contactos.vue";

import { RitttzyFooter } from "@ritttzy/vue-brand";

export default defineComponent({
  name: "App",
  components: {
    Menu,
    Inicio,
    Equipo,
    Beneficios,
    Productos,
    Contactos,
    Preguntas,
    RitttzyFooter,
  },
});
